import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

import FormField from "layouts/pages/users/new-user/components/FormField";
import { Fragment } from "react";
import FormHelperText from "@mui/material/FormHelperText";
import SignUpCreateNewOrganizationFormErrorsBlock from "./SignUpCreateNewOrganizationFormErrorsBlock";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";

const SignUpCreateNewOrganizationFormikWrapper = (props) => {
  const {
    values,
    errors,
    touched,
    setFieldValue,
    isSubmitting,
    handleBack,
    objCreateNewOrganizationErrors,
    isLoading,
    objStates,
    objCountries,
  } = props;
  console.log(values.drpCountry);
  return (
    <Fragment>
      <MDBox my={3} textAlign="center">
        <MDTypography mb={1} variant="h4" fontWeight="bold">
          About MSP
        </MDTypography>
        <MDTypography textAlign="center" variant="body2" color="text">
          Give us more details about your MSP?
        </MDTypography>
        <MDBox mt={1.625}>
          <Grid container xs={12} justifyContent="space-between" p={5}>
            <Grid container xs={12} md={8} spacing={3}>
              <Grid item xs={12}>
                <FormField
                  type="text"
                  label="MSP Name"
                  name="txtCompanyName"
                  value={values.txtCompanyName}
                  placeholder={"Company Name"}
                  error={errors.txtCompanyName && touched.txtCompanyName}
                  success={
                    values.txtCompanyName.length > 0 && !errors.txtCompanyName
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <FormField
                  type="text"
                  label="Address 1"
                  name="txtAddress1"
                  value={values.txtAddress1}
                  placeholder={"Address 1"}
                  error={errors.txtAddress1 && touched.txtAddress1}
                  success={values.txtAddress1.length > 0 && !errors.txtAddress1}
                />
              </Grid>
              <Grid item xs={12}>
                <MDBox mt={-1.625}>
                  <FormField
                    type="text"
                    label="Address 2"
                    name="txtAddress2"
                    value={values.txtAddress2}
                    placeholder={"Address 2"}
                    error={errors.txtAddress2 && touched.txtAddress2}
                    success={
                      values.txtAddress2.length > 0 && !errors.txtAddress2
                    }
                  />
                </MDBox>
              </Grid>
            </Grid>
            <Grid container xs={12} md={4} spacing={3}>
              <Grid item xs={12}>
                <FormField
                  type="text"
                  label="City"
                  name="txtCity"
                  value={values.txtCity}
                  placeholder={"City"}
                  error={errors.txtCity && touched.txtCity}
                  success={values.txtCity.length > 0 && !errors.txtCity}
                />
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  {values.drpCountry === "United States of America" && (
                    <Grid item xs={6}>
                      <Autocomplete
                        options={objStates}
                        renderInput={(params) => (
                          <MDInput
                            {...params}
                            variant="standard"
                            label="State"
                          />
                        )}
                        onChange={(event, value) => {
                          if (value === null) {
                            setFieldValue("drpState", "");
                          } else {
                            setFieldValue("drpState", value.label);
                          }
                        }}
                      />
                      {errors.drpState && touched.drpState ? (
                        <FormHelperText error>{errors.drpState}</FormHelperText>
                      ) : null}
                    </Grid>
                  )}
                  <Grid
                    item
                    xs={
                      values.drpCountry === "United States of America" ? 6 : 12
                    }
                  >
                    <Autocomplete
                      options={objCountries}
                      defaultValue={values.drpCountry}
                      renderInput={(params) => (
                        <MDInput
                          {...params}
                          variant="standard"
                          label="Country"
                        />
                      )}
                      onChange={(event, value) => {
                        if (value === null) {
                          setFieldValue("drpCountry", "");
                        } else {
                          setFieldValue("drpCountry", value.label);
                        }
                      }}
                    />
                    {errors.drpCountry && touched.drpCountry ? (
                      <FormHelperText error>{errors.drpCountry}</FormHelperText>
                    ) : null}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <FormField
                  type="number"
                  label="Zip"
                  name="txtZip"
                  value={values.txtZip}
                  placeholder={"Zip"}
                  error={errors.txtZip && touched.txtZip}
                  success={values.txtZip.length > 0 && !errors.txtZip}
                />
              </Grid>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      {Object.keys(objCreateNewOrganizationErrors).length > 0 ? (
        <SignUpCreateNewOrganizationFormErrorsBlock
          objCreateNewOrganizationErrors={objCreateNewOrganizationErrors}
        />
      ) : null}
      {isLoading === false ? (
        <MDBox
          mt={2}
          width="100%"
          display="flex"
          justifyContent="space-between"
        >
          <MDButton variant="gradient" color="light" onClick={handleBack}>
            prev
          </MDButton>

          <MDButton
            disabled={isSubmitting}
            type="submit"
            variant="gradient"
            color="dark"
          >
            next
          </MDButton>
        </MDBox>
      ) : (
        <MDBox mt={2} width="100%" display="flex" justifyContent="center">
          {/* <LoadingSpinner subClass="text-center" color="success" size="lg" /> */}
        </MDBox>
      )}
    </Fragment>
  );
};

export default SignUpCreateNewOrganizationFormikWrapper;
