import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useState } from "react";
import { Formik, Form } from "formik";

import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { showMsg } from "utils/general";
import SignUpCreateNewOrganizationFormikWrapper from "./SignUpCreateNewOrganizationFormikWrapper";
import { storeCompany } from "features/user/userSignUpFunnelActions";
import { auth } from "features/user/userActions";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Grid } from "@mui/material";
import MDButton from "components/MDButton";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
const objInitialValues = {
  txtCompanyName: "",
  txtAddress1: "",
  txtAddress2: "",
  drpCountry: "United States of America",
  txtCity: "",
  drpState: "",
  txtZip: "",
};
const FormJWTSchema = Yup.object().shape({
  txtCompanyName: Yup.string().required("Company Name is required."),
  txtAddress1: Yup.string().required("Address 1 is required."),
  drpCountry: Yup.string().required("Country is required."),
  txtCity: Yup.string().required("City is required."),
  // drpState: Yup.string().required("State is required."),
  txtZip: Yup.string()
    .required("Zip code is required.")
    .min(4, "Please enter a valid zip code."),
});
const SignUpCreateNewOrganizationForm = (props) => {
  const { objStates, objCountries } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [toBeSubmittedValues, setToBeSubmittedValues] = useState({});
  const [openNotifyModal, setOpenNotifyModal] = useState(false);
  const [objCreateNewOrganizationErrors, setObjCreateNewOrganizationErrors] =
    useState({});

  const handleBack = () => {
    navigate("/setup-account/step-one");
  };

  const onSubmitForm = async (values) => {
    setToBeSubmittedValues(values);
    setOpenNotifyModal(true);
  };
  const submitFormFinal = async () => {
    const values = { ...toBeSubmittedValues };

    if (values.drpCountry !== "United States of America") {
      values.drpState = "";
    } else if (
      values.drpCountry === "United States of America" &&
      values.drpState === ""
    ) {
      showMsg("error", "State is required.");
      return;
    }

    const objPostData = {
      name: values.txtCompanyName,
      address_1: values.txtAddress1,
      address_2: values.txtAddress2,
      country: values.drpCountry,
      city: values.txtCity,
      state: values.drpState,
      zip: values.txtZip,
    };
    setObjCreateNewOrganizationErrors({});
    setIsLoading(true);
    dispatch(storeCompany(objPostData))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoading(false);
        const response = originalPromiseResult;
        if (response.status === 201) {
          setIsLoading(true);
          showMsg("success", "Your company has been successfully created.");
          dispatch(auth()).then((originalPromiseResultAuth) => {
            setIsLoading(false);
            // navigate("/sign-up/pick-a-plan");
            navigate("/billing");
          });
        } else {
          showMsg(
            "error",
            "Something went wrong on company creation. Please try again!"
          );
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoading(false);
        // showMsg("error", "Please correct the errors.");
        const objErrors = rejectedValueOrSerializedError.response.data.errors;
        if (objErrors !== undefined) {
          setObjCreateNewOrganizationErrors(objErrors);
        }
        //else {
        //   showMsg("error", "Something went wrong, please try again");
        // }
        if (rejectedValueOrSerializedError.response.data.errors) {
          rejectedValueOrSerializedError.response.data.errors.forEach(
            (error) => {
              showMsg("error", error);
            }
          );
        } else if (rejectedValueOrSerializedError.response.data.message) {
          showMsg(
            "error",
            rejectedValueOrSerializedError.response.data.message
          );
        } else {
          showMsg("error", "Something went wrong");
        }
      });
  };

  const onCloseNotifyModal = () => {
    setOpenNotifyModal(false);
  };
  return (
    <>
      <Dialog
        open={openNotifyModal}
        onClose={onCloseNotifyModal}
        fullWidth={true}
      >
        <DialogTitle sx={{ pb: 0, pl: 2 }}>Before Your Proceed</DialogTitle>
        <DialogContent>
          <Grid spacing={2} sx={{ ml: 0, width: "100%", mt: 2 }} fullWidth>
            <Grid item xs={12} lg={12}>
              To complete your account setup, you will need to provide billing
              details and company information. For personalized product
              recommendations and pricing options, please reach out to our sales
              team directly by visiting{" "}
              <a
                href="https://compliancescorecard.com/contact-us/"
                target="_blank"
                rel="noreferrer"
              >
                https://compliancescorecard.com/contact-us/
              </a>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          {isLoading ? (
            <LoadingSpinner subClass="text-center" color="success" size="lg" />
          ) : (
            <MDButton
              onClick={() => submitFormFinal()}
              type="submit"
              color="success"
              sx={{ padding: "12px 12px" }}
            >
              Create Account
            </MDButton>
          )}
          <MDButton
            type="button"
            variant="outlined"
            color="dark"
            sx={{ padding: "12px 12px" }}
            onClick={onCloseNotifyModal}
          >
            Cancel
          </MDButton>
        </DialogActions>
      </Dialog>
      <Formik
        initialValues={objInitialValues}
        validationSchema={FormJWTSchema}
        onSubmit={onSubmitForm}
      >
        {({ values, errors, touched, isSubmitting, setFieldValue }) => (
          <Form id={"new-company-form"} autoComplete="off">
            <SignUpCreateNewOrganizationFormikWrapper
              values={values}
              touched={touched}
              errors={errors}
              setFieldValue={setFieldValue}
              isSubmitting={isSubmitting}
              handleBack={handleBack}
              objCreateNewOrganizationErrors={objCreateNewOrganizationErrors}
              isLoading={isLoading}
              objStates={objStates}
              objCountries={objCountries}
            />

            {/* <MDBox mt={4} mb={1}>
              {isLoading === false ? (
                <MDButton
                  type="submit"
                  variant="gradient"
                  color="success"
                  size="large"
                  fullWidth
                  // onClick={onSubmitForm}
                >
                  sign in
                </MDButton>
              ) : (
                <LoadingSpinner subClass="text-center" color="success" size="lg" />
              )}
            </MDBox> */}
          </Form>
        )}
      </Formik>
    </>
  );
};

export default SignUpCreateNewOrganizationForm;
